// Generated by Framer (92b2253)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/bshAVwZPg";
import * as sharedStyle1 from "../css/eLnqWn3SI";
import * as sharedStyle2 from "../css/RkYAkrE6f";

const cycleOrder = ["aWxcNOMN4"];

const serializationHash = "framer-J1vG4"

const variantClassNames = {aWxcNOMN4: "framer-v-10e03pt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, phase, phaseDescription, phaseNumber, width, ...props}) => { return {...props, i2NDamtM2: phase ?? props.i2NDamtM2 ?? "Discovery", mwj_4Vm5m: phaseNumber ?? props.mwj_4Vm5m ?? "(01)", nXsEgZnQZ: phaseDescription ?? props.nXsEgZnQZ ?? "The first part of my process is about learning all about your sector."} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;phaseNumber?: string;phase?: string;phaseDescription?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mwj_4Vm5m, i2NDamtM2, nXsEgZnQZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aWxcNOMN4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className, sharedStyle2.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10e03pt", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aWxcNOMN4"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1m8v0l6"} data-styles-preset={"bshAVwZPg"}>(01)</motion.p></React.Fragment>} className={"framer-hjl3x5"} data-framer-name={"(01)"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"nHSBBEUyb"} text={mwj_4Vm5m} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-xqbgfd"} data-framer-name={"Phase Content"} layoutDependency={layoutDependency} layoutId={"XPgcOVWE9"}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-13pjvst"} data-styles-preset={"eLnqWn3SI"}>Discovery</motion.h3></React.Fragment>} className={"framer-lbi8tm"} data-framer-name={"Discovery"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"t35LM_fHL"} text={i2NDamtM2} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1fq2utu"} data-styles-preset={"RkYAkrE6f"}>The first part of my process is about learning all about your industry.</motion.p></React.Fragment>} className={"framer-19pmycg"} data-framer-name={"The first part of my process is about learning all about your industry."} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"XmUX0jokK"} text={nXsEgZnQZ} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-J1vG4.framer-kp8pnt, .framer-J1vG4 .framer-kp8pnt { display: block; }", ".framer-J1vG4.framer-10e03pt { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 372px; }", ".framer-J1vG4 .framer-hjl3x5, .framer-J1vG4 .framer-lbi8tm, .framer-J1vG4 .framer-19pmycg { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", ".framer-J1vG4 .framer-xqbgfd { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-J1vG4.framer-10e03pt, .framer-J1vG4 .framer-xqbgfd { gap: 0px; } .framer-J1vG4.framer-10e03pt > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-J1vG4.framer-10e03pt > :first-child, .framer-J1vG4 .framer-xqbgfd > :first-child { margin-top: 0px; } .framer-J1vG4.framer-10e03pt > :last-child, .framer-J1vG4 .framer-xqbgfd > :last-child { margin-bottom: 0px; } .framer-J1vG4 .framer-xqbgfd > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } }", ...sharedStyle.css, ...sharedStyle1.css, ...sharedStyle2.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 159
 * @framerIntrinsicWidth 372
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"mwj_4Vm5m":"phaseNumber","i2NDamtM2":"phase","nXsEgZnQZ":"phaseDescription"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIaSZdVVfV: React.ComponentType<Props> = withCSS(Component, css, "framer-J1vG4") as typeof Component;
export default FramerIaSZdVVfV;

FramerIaSZdVVfV.displayName = "Phase";

FramerIaSZdVVfV.defaultProps = {height: 159, width: 372};

addPropertyControls(FramerIaSZdVVfV, {mwj_4Vm5m: {defaultValue: "(01)", displayTextArea: false, title: "Phase Number", type: ControlType.String}, i2NDamtM2: {defaultValue: "Discovery", displayTextArea: false, title: "Phase", type: ControlType.String}, nXsEgZnQZ: {defaultValue: "The first part of my process is about learning all about your sector.", displayTextArea: false, title: "Phase Description", type: ControlType.String}} as any)

addFonts(FramerIaSZdVVfV, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts), ...getFontsFromSharedStyle(sharedStyle2.fonts)], {supportsExplicitInterCodegen: true})